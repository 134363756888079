
import { updateSetting } from "../store/admin/settingsSlice";
import { get } from "./api";

export const adminApi = {
    updateSubjectFaculty: async (payload) => {
        let url = `admin/updatefaculty`;
        return get(url, "json", payload).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    addFaculty: async (payload) => {
        let url = `admin/addfaculty`;
        return get(url, "json", payload).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    addUser: async (payload) => {
        let url = `admin/adduser`;
        return get(url, "json", payload).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    deleteUser: async (payload) => {
        let url = `admin/deleteuser`;
        return get(url, "json", payload).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    deleteFaculty: async (payload) => {
        let url = `admin/deletefaculty`;
        return get(url, "json", payload).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getUsers: async (filter) => {
        let url = `admin/users`;

        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getSubjects: async (id) => {
        let url = `dash/subjects/`;

        return get(url).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    changeUserStatus: async (payload) => {
        let url = `admin/changeuserstatus`;
        return get(url, "json", payload).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    changeUserAdmin: async (payload) => {
        let url = `admin/changeuseradmin`;
        return get(url, "json", payload).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getTermDates: async () => {
        let url = `admin/termdates`;
        return get(url).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    updateTermDates: async (payload) => {
        let url = `admin/updatetermdates`;
        return get(url, "json", payload).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    resetDatabase: async (payload) => {
        let url = `admin/resetdatabase`;
        return get(url, "json", payload).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getSettings: async () => {
        let url = `admin/loadadminsettings`;
        return get(url, "json", {}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    updateSetting: async (payload) => {
        let url = `admin/updateadminsetting`;
        return get(url, "json", payload).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getSchool: async () => {
        let url = `user/myschool`;
        return get(url).then((res) => {
            if (res) {
                return res;
            }
        });
    }
};
