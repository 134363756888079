import { get } from "./api";

export const attendanceApi = {
    getByCode: async (filter) => {
        let url = `attendance/bycode`;
        
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },
    getByCodeType: async (filter) => {
        let url = `attendance/bycodetype`;
        
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getByTerm: async (filter) => {
        let url = `attendance/byterm`;
        
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getByWeekDay: async (filter) => {
        let url = `attendance/byweekday`;
        
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getSummary: async (filter) => {
        let url = `attendance/summary`;
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getTable: async (filter) => {
        let url = `attendance/table`;
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

    getLateReport: async (filter) => {
        let url = `attendance/latereport`;
        return get(url, "json", {filter}).then((res) => {
            if (res) {
                return res;
            }
        });
    },

};
