import React, { useEffect } from "react";
import { PieChart } from "../charts/PieChart";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Digits } from "../charts/Digits";
import { LineChart } from "../charts/LineChart";
import { BarChart } from "../charts/BarChart";
import {
    getByType,
    getPointsSummary,
    getBehaviourAndAchievementByWeek,
    getBehaviourAndAchievementByMonth,
    getBehaviourAndAchievementByTerm,
    getByStudentType,
    getIncidentsSummary,
    getBySubject,
    getByClass,
    getByTutor,
    getByStaff
} from "../../store/dashboard/chartsSlice";
import { Loader } from "../general/Loader";

export const DashboardOverview = () => {
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.dash.filters);
    const excludeFilters = useSelector((state) => state.dash.excludefilters);
    const hiddenFilters = useSelector((state) => state.dash.hiddenFilters);

    // Dashboard data
    const byTypeData = useSelector((state) => state.charts.byType.data);
    const pointsData = useSelector((state) => state.charts.pointsSummary.data);
    const incidentsData = useSelector((state) => state.charts.incidentsSummary.data);
    const monthData = useSelector((state) => state.charts.allByMonth.data);
    const termData = useSelector((state) => state.charts.allByTerm.data);
    const classData = useSelector((state) => state.charts.byClass.data);
    const tutorGroupData = useSelector((state) => state.charts.byTutorGroup.data);
    const staffData = useSelector((state) => state.charts.byStaff.data);
    const subjectData = useSelector((state) => state.charts.bySubject.data);
    const studentType = useSelector((state) => state.charts.byStudentType.data);
    const weekData = useSelector((state) => state.charts.byWeek.data);

    const byTypeStatus = useSelector((state) => state.charts.byType.status);
    const pointsStatus = useSelector((state) => state.charts.pointsSummary.status);
    const incidentsstatus = useSelector((state) => state.charts.incidentsSummary.status);
    const monthstatus = useSelector((state) => state.charts.allByMonth.status);
    const termstatus = useSelector((state) => state.charts.allByTerm.status);
    const classstatus = useSelector((state) => state.charts.byClass.status);
    const tutorGroupstatus = useSelector((state) => state.charts.byTutorGroup.status);
    const staffstatus = useSelector((state) => state.charts.byStaff.status);
    const subjectstatus = useSelector((state) => state.charts.bySubject.status);
    const studentTypestatus = useSelector((state) => state.charts.byStudentType.status);
    const weekstatus = useSelector((state) => state.charts.byWeek.status);

    const dashloadstatus = useSelector((state) => state.dash.loadStatus);

    useEffect(() => {
        if (dashloadstatus === "loading" || dashloadstatus === "unloaded") return;
        dispatch(getByType({include: [...filters, ...hiddenFilters], exclude: excludeFilters})); //{ key: "conducttype", value: "Behaviour" }
        dispatch(getPointsSummary({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        dispatch(getIncidentsSummary({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        dispatch(getBehaviourAndAchievementByMonth({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        dispatch(getBehaviourAndAchievementByTerm({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        dispatch(getBySubject({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        dispatch(getBehaviourAndAchievementByWeek({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        dispatch(getByClass({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        dispatch(getByTutor({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        dispatch(getByStaff({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        dispatch(getByStudentType({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
        //dispatch(getAttendance({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
       // dispatch(getByEthnicity({include: [...filters, ...hiddenFilters], exclude: excludeFilters}));
    }, [dispatch, filters, excludeFilters, hiddenFilters, dashloadstatus]);

    if (
        byTypeStatus === "loading" ||
        pointsStatus === "loading" ||
        incidentsstatus === "loading" ||
        monthstatus === "loading" ||
        termstatus === "loading" ||
        classstatus === "loading" ||
        tutorGroupstatus === "loading" ||
        staffstatus === "loading" ||
        subjectstatus === "loading" ||
        studentTypestatus === "loading" ||
        weekstatus === "loading" 
    ) {
        return <Loader />;
    }
    return (
        <div className="dashboard-area row">
            {byTypeStatus === "loading" ? <></> : <PieChart title="Conduct by type" filterkey="category" data={byTypeData} />}
            {pointsStatus === "loading" ? <></> : <Digits title="Points" data={pointsData} />}
            {incidentsstatus === "loading" ? <></> : <Digits title="Incidents/Awards" data={incidentsData} />}
            {monthstatus === "loading" ? <></> : <PieChart title="Conduct by subject" filterkey="subjectname" data={subjectData} />}
            {termstatus === "loading" ? <></> : <LineChart title="Conduct by month" filterkey="month" data={monthData} />}
            {classstatus === "loading" ? <></> : <BarChart title="Conduct by term" filterkey="term" data={termData} />}
            {tutorGroupstatus === "loading" ? <></> : <PieChart title="Conduct by class" filterkey="class" data={classData} />}
            {staffstatus === "loading" ? (
                <></>
            ) : (
                <BarChart title="Conduct by tutor group" filterkey="tutorgroupname" data={tutorGroupData} />
            )}
            {subjectstatus === "loading" ? <></> : <PieChart title="Conduct by staff" filterkey="staffcode" data={staffData} />}
            {studentTypestatus === "loading" ? (
                <></>
            ) : (
                <BarChart title="Conduct by student type" filterkey="studenttype" data={studentType} />
            )}
            {weekstatus === "loading" ? <></> : <LineChart title="Conduct by week" filterkey="week" data={weekData} />}
            
        </div>
    );
    //return (<div>Overview</div>);
};
