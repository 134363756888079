import React from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getLateReport } from "../../store/dashboard/attendanceSlice";
import DownloadCSV from "../general/DownloadCSV";
import { Loader } from "../general/Loader";
import { setLateReportDays, setLateReportMinutes } from "../../store/dashboard/attendanceSlice";
import { useRef } from "react";

export const LessonAttendance = () => {
    const dispatch = useDispatch();
    const attendanceReport = useSelector((state) => state.attendance.lateReport);
    const timed = useRef(null);
    const filterChange = useRef(false);

    useEffect(() => {
        if (filterChange.current) {
            if (timed.current) {
                clearTimeout(timed.current);
            }
            timed.current = setTimeout(() => {
                filterChange.current = false;
                dispatch(getLateReport({ days: attendanceReport.days, minutes: attendanceReport.minutes }));
            }, 500);
        } else {
            dispatch(getLateReport({ days: attendanceReport.days, minutes: attendanceReport.minutes }));
        }
    }, [dispatch, attendanceReport.days, attendanceReport.minutes]);

    const handleDaysChange = (e) => {
        if (e.target.value && !isNaN(e.target.value)) {
            filterChange.current = true;
            dispatch(setLateReportDays(e.target.value));
        }
    };

    const handleMinutesChange = (e) => {
        if (e.target.value && !isNaN(e.target.value)) {
            filterChange.current = true;
            dispatch(setLateReportMinutes(e.target.value));
        }
    };

    const render = () => {
        if (attendanceReport.status === "loading") {
            return <Loader />;
        } else if (attendanceReport.status === "error") {
            return <div>Error: {attendanceReport.error}</div>;
        } else {
            return (
                <div>
                    <h1>Lesson attendance</h1>
                    <hr />
                    <h2>Late Report</h2>
                    <p>
                        Students who have <input type="number" onChange={handleMinutesChange} value={attendanceReport.minutes} /> mins or more of late marks
                        in the last <input type="number" onChange={handleDaysChange} value={attendanceReport.days} /> days
                    </p>
                    <p>
                        <DownloadCSV
                            data={attendanceReport.data}
                            fileName={"attendance-report"}
                            header={["id", "firstname", "lastname", "tutor", "minslate", "totallates"]}
                        />
                    </p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Student</th>
                                <th>Number of Lates</th>
                                <th>Total Minutes Late</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceReport.data &&
                                attendanceReport.data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Link to={`/Dashboard/Students/${item.stuid}`}>
                                                    {item.firstname} {item.lastname} ({item.tutorgroup})
                                                </Link>
                                            </td>
                                            <td>{item.timeslate}</td>
                                            <td>{item.totalmins}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    return render();
};
