import React, {useEffect} from 'react';
import { PieChart } from "../charts/PieChart";
import { BarChart } from "../charts/BarChart";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Loader } from "../general/Loader";
import {getByCode, getByTerm, getByCodeType, getByWeekDay, getSummary} from "../../store/dashboard/attendanceSlice";
import { Digits } from '../charts/Digits';

export const DashboardAttendance = () => {
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.dash.filters);
    const excludeFilters = useSelector((state) => state.dash.excludefilters);
    const hiddenFilters = useSelector((state) => state.dash.hiddenFilters);

    const byCodeData = useSelector((state) => state.attendance.byCode.data);
    const byCodeStatus = useSelector((state) => state.attendance.byCode.status);

    const byCodeTypeData = useSelector((state) => state.attendance.byCodeType.data);
    const byCodeTypeStatus = useSelector((state) => state.attendance.byCodeType.status);

    const byTermData = useSelector((state) => state.attendance.byTerm.data);
    const byTermStatus = useSelector((state) => state.attendance.byTerm.status);

    const byWeekDayData = useSelector((state) => state.attendance.byWeekDay.data);
    const byWeekDayStatus = useSelector((state) => state.attendance.byWeekDay.status);

    const summaryData = useSelector((state) => state.attendance.summary.data);
    const summaryStatus = useSelector((state) => state.attendance.summary.status);


    const dashloadstatus = useSelector((state) => state.dash.loadStatus);


    useEffect(() => {
        if (dashloadstatus === "loading" || dashloadstatus === "unloaded") return;
        dispatch(getByCode({include: [...filters, ...hiddenFilters, {  }], exclude: excludeFilters}));
        dispatch(getByCodeType({include: [...filters, ...hiddenFilters, {  }], exclude: excludeFilters}));
        dispatch(getByTerm({include: [...filters, ...hiddenFilters, {  }], exclude: excludeFilters}));
        dispatch(getByWeekDay({include: [...filters, ...hiddenFilters, {  }], exclude: excludeFilters}));
        dispatch(getSummary({include: [...filters, ...hiddenFilters, {  }], exclude: excludeFilters}));


    }, [dispatch, filters, excludeFilters, hiddenFilters, dashloadstatus]);


    if (byCodeStatus === "loading" || byTermStatus === "loading" || byCodeTypeStatus === "loading" || byWeekDayStatus === "loading" || summaryStatus === "loading") {
        return <Loader />;
    }



  return (
    <div className="dashboard-area row">
        <Digits title="Attendance Summary" data={summaryData} />
        <PieChart title="Attendance Codes" size={2} data={byCodeData} filterkey="code_desc" />
        <PieChart title="Attendance Type" size={2} data={byCodeTypeData} filterkey="code_type" />
        <BarChart title="Attendance By Term" data={byTermData} filterkey="term" />
        <BarChart title="Attendance By Day" data={byWeekDayData} filterkey="dayname" />
    </div>
  )
}
