import React, { useEffect } from "react";
import { Loader } from "../general/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAttendanceTable } from "../../store/dashboard/attendanceSlice";
import { AttendanceTableItem } from "../lists/AttendanceTableItem";

export const DashboardAttendanceTable = () => {
    const dispatch = useDispatch();
    const attendanceData = useSelector((state) => state.attendance.attendanceTable.data);
    const attendanceStatus = useSelector((state) => state.attendance.attendanceTable.status);
    const order = useSelector((state) => state.attendance.attendanceTable.order);
    const filters = useSelector((state) => state.dash.filters);
    const excludeFilters = useSelector((state) => state.dash.excludefilters);
    const hiddenFilters = useSelector((state) => state.dash.hiddenFilters);
    const dashloadstatus = useSelector((state) => state.dash.loadStatus);
    

    useEffect(() => {
        if (dashloadstatus === "loading" || dashloadstatus === "unloaded") return;
        dispatch(getAttendanceTable({include: [...filters, ...hiddenFilters, { key: order }], excllude: excludeFilters}));
    }, [dispatch, filters, excludeFilters, hiddenFilters, order, dashloadstatus]);

    const renderItems = () => {
        const items = attendanceData.map((c, index) => {
            return <AttendanceTableItem key={c.studentid} data={c} index={index + 1} />;
        });
        return items;
    };

    const orderby = (field) => {
        // dispatch(setConductTableOrder(field));
    };

    return attendanceStatus === "loading" ? (
        <Loader />
    ) : (
        <div>
            <table className="table table-striped">
                <thead className="">
                    <tr>
                        <th>#</th>
                        <th>Student</th>
                        <th
                            className={`dash-table-order ${order === "orderby:sumpoints" ? "dash-table-order-selected" : ""}`}
                            onClick={() => orderby("orderby:sumpoints")}
                        >
                            Attendance
                        </th>
                        <th
                            className={`dash-table-order ${order === "orderby:count" ? "dash-table-order-selected" : ""}`}
                            onClick={() => orderby("orderby:count")}
                        >
                            Present / Absent
                        </th>
                    </tr>
                </thead>
                <tbody>{renderItems()}</tbody>
            </table>
        </div>
    );
};
