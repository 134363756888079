import React from "react";
import { Link } from "react-router-dom";

export const AttendanceTableItem = ({data, index}) => {
    return (
        <tr className="attendancetableitem">
            <td>{index}</td>
            <td>
                <Link to={`/Dashboard/Students/${data.studentid}`}> {data.student_firstname} {data.student_lastname}</Link>{" "}
                (<Link to={`/Dashboard/TutorGroups/${data.tutorgroupid}`}>{data.tutorgroup_name}</Link>) 
            </td>
            <td>
                {data.attendance}
            </td>
            <td>
                {data.present} / {parseInt(data.authorised) + parseInt(data.unauthorised)}
            </td>
        </tr>
    );
};
