import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dashApi } from "./../../api/api.dash";

const initialState = {
    name: "",
    type: "",
    id: "",
    route: "",
    relatedBoards: [],
    filters: [],
    excludefilters: [],
    hiddenFilters: [],
    loadStatus: "unloaded",
    loadError: "",
};

//============================================================================//
//================================== Helpers =================================//

const getWholeschool = async () => {
    const apiResponse = await dashApi.getKeystages();
    if (apiResponse.success) {
        const data = apiResponse.data.map((item) => {
            return { name: item.name, route: `/Dashboard/Keystages/${item.keystageid}` };
        });
        return {
            success: true,
            data: {
                name: "Whole School",
                relatedBoards: data,
            },
            error: null,
        };
    } else {
        return {
            success: false,
            data: null,
            error: apiResponse.error,
        };
    }
};

const getKeystage = async (id) => {
    const apiResponse = await dashApi.getKeystage(id);
    if (apiResponse.success) {
        const ksData = apiResponse.data.yeargroups.map((year) => {
            return { name: year.name, route: `/Dashboard/YearGroups/${year.yeargroupid}` };
        });
        return {
            success: true,
            data: {
                name: apiResponse.data.name,
                hiddenFilters: [{key: "keystage", value: id}],
                relatedBoards: ksData,
            },
            error: null,
        };
    } else {
        return {
            success: false,
            data: null,
            error: apiResponse.error,
        };
    }
};

const getYearGroup = async (id) => {
    const apiResponse = await dashApi.getYearGroup(id);
    if (apiResponse.success) {
        const ksData = apiResponse.data.tutorgroups.map((tutor) => {
            return { name: tutor.name, route: `/Dashboard/TutorGroups/${tutor.tutorgroupid}` };
        });
        return {
            success: true,
            data: {
                name: apiResponse.data.name,
                hiddenFilters: [{key: "yeargroup", value: id}],
                relatedBoards: ksData,
            },
            error: null,
        };
    } else {
        return {
            success: false,
            data: null,
            error: apiResponse.error,
        };
    }
};

const getTutorGroup = async (id) => {
    const apiResponse = await dashApi.getTutorGroup(id);
    if (apiResponse.success) {
        const rData = apiResponse.data.students.map((student) => {
            return { name: `${student.firstname} ${student.lastname}`, route: `/Dashboard/Students/${student.studentid}` };
        });
        return {
            success: true,
            data: {
                name: apiResponse.data.name,
                hiddenFilters: [{key: "tutorgroup", value: id}],
                relatedBoards: rData,
            },
            error: null,
        };
    } else {
        return {
            success: false,
            data: null,
            error: apiResponse.error,
        };
    }
};

const getStudent = async (id) => {
    const apiResponse = await dashApi.getStudent(id);
    if (apiResponse.success) {
        return {
            success: true,
            data: {
                name: `${apiResponse.data.firstname} ${apiResponse.data.lastname} (${apiResponse.data.tutorgroup})`,
                hiddenFilters: [{key: "student", value: id}],
                relatedBoards: [],
            },
            error: null,
        };
    } else {
        return {
            success: false,
            data: null,
            error: apiResponse.error,
        };
    }
};

const getStaff = async (id) => {
    const apiResponse = await dashApi.getStaffMember(id);
    if (apiResponse.success) {
        return {
            success: true,
            data: {
                name: `${apiResponse.data.name} (${apiResponse.data.staffcode})`,
                hiddenFilters: [{key: "staff", value: id}],
                relatedBoards: [],
            },
            error: null,
        };
    } else {
        return {
            success: false,
            data: null,
            error: apiResponse.error,
        };
    }
};

const getFaculty = async (id) => {
    const apiResponse = await dashApi.getFaculty(id);
    if (apiResponse.success) {
        const rData = apiResponse.data.subjects.map((subject) => {
            return { name: subject.name, route: `/Dashboard/Subjects/${subject.subjectid}` };
        });
        return {
            success: true,
            data: {
                name: apiResponse.data.name,
                hiddenFilters: [{key: "faculty", value: id}],
                relatedBoards: rData,
            },
            error: null,
        };
    } else {
        return {
            success: false,
            data: null,
            error: apiResponse.error,
        };
    }
};

const getSubject = async (id) => {
    const apiResponse = await dashApi.getSubject(id);
    if (apiResponse.success) {
        return {
            success: true,
            data: {
                name: apiResponse.data.name,
                hiddenFilters: [{key: "subject", value: id}],
                relatedBoards: [],
            },
            error: null,
        };
    } else {
        return {
            success: false,
            data: null,
            error: apiResponse.error,
        };
    }
};


//============================================================================//
//================================== Thunks ==================================//

export const loadDashboard = createAsyncThunk("dash/loadDashboard", async (payload, thunkAPI) => {
    // This is where we check the dashboard type and decide which api call to make.
    let response = {};
    if (payload.type === "staff") {
        response = await getStaff(payload.id);
    } else if (payload.type === "subject") {
        response = await getSubject(payload.id);
    } else if (payload.type === "faculty") {
        response = await getFaculty(payload.id);
    } else if (payload.type === "student") {
        response = await getStudent(payload.id);
    } else if (payload.type === "tutor") {
        response = await getTutorGroup(payload.id);
    } else if (payload.type === "year") {
        response = await getYearGroup(payload.id);
    } else if (payload.type === "keystage") {
        response = await getKeystage(payload.id);
    } else if (payload.type === "wholeschool") {
        response = await getWholeschool();
    }

    if (response.success) {
        return response.data;
    } else {
        console.error("Error loading dashboard", response.error)
        return thunkAPI.rejectWithValue(response.error);
    }
});

//============================================================================//
//================================== Slice ===================================//

export const dashSlice = createSlice({
    name: "dash",
    initialState,
    reducers: {
        addFilter: (state, action) => {
           /* // Look for duplicates with same key
            const index = state.filters.findIndex((filter) => filter.key === action.payload.key);
            if (index !== -1) {
                state.filters[index] = action.payload;
                return;
            }*/

            state.filters = [...state.filters, action.payload];
        },
        addExcludeFilter: (state, action) => {
            state.excludefilters = [...state.excludefilters, action.payload];
        },
        removeFilter: (state, action) => {
            //state.filters = state.filters.filter((filter) => filter.key !== action.payload);
            state.filters = state.filters.filter((filter) => `${filter.key}${filter.value}` !== `${action.payload.key}${action.payload.value}`);
        },
        removeExcludeFilter: (state, action) => {
            state.excludefilters = state.excludefilters.filter((filter) => `${filter.key}${filter.value}` !== `${action.payload.key}${action.payload.value}`);
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setRoute: (state, action) => {
            state.route = action.payload;
        },
        setId: (state, action) => {
            state.id = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDashboard.pending, (state) => {
                state.loadStatus = "loading";
            })
            .addCase(loadDashboard.fulfilled, (state, action) => {
                state.loadStatus = "idle";
                state.name = action.payload.name;
                state.hiddenFilters = action.payload.hiddenFilters ?? [];
                state.relatedBoards = action.payload.relatedBoards;
            })
            .addCase(loadDashboard.rejected, (state, action) => {
                state.loadStatus = "idle";
                state.loadError = action.payload;
            });
    },
});

export const { addFilter, addExcludeFilter, removeFilter, removeExcludeFilter, setRoute, setName, setType, setId } = dashSlice.actions;

export default dashSlice.reducer;
