import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { attendanceApi } from "../../api/api.attendance";

const initialState = {
    byCode: {
        data: [],
        status: "idle",
        error: "",
    },
    byCodeType: {
        data: [],
        status: "idle",
        error: "",
    },
    byTerm: {
        data: [],
        status: "idle",
        error: "",
    },
    byWeekDay: {
        data: [],
        status: "idle",
        error: "",
    },
    summary: {
        data: [],
        status: "idle",
        error: "",
    },
    attendanceTable: {
        data: [],
        status: "idle",
        error: "",
    },
    lateReport: {
        data: [],
        minutes: 10,
        days: 7,
        status: "idle",
        error: "",
    },
};

export const getLateReport = createAsyncThunk("attendance/getLateReport", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getLateReport(payload);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getAttendanceTable = createAsyncThunk("attendance/getAttendanceTable", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getTable(payload);
    if (apiResponse.success) {
        return apiResponse.data;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getSummary = createAsyncThunk("attendance/getSummary", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getSummary(payload);
    if (apiResponse.success) {
        return [
            { Order: 1, label: "Present", value: apiResponse.data.present },
            { Order: 2, label: "Authorised", value: apiResponse.data.authorised },
            { Order: 3, label: "Unauthorised", value: apiResponse.data.unauthorised },
            { Order: 5, label: "Attendance %", value: apiResponse.data.attendance },
        ];
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getByCode = createAsyncThunk("attendance/getByCode", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getByCode(payload);
    if (apiResponse.success) {
        const returnData = apiResponse.data.map((item) => item.count);
        const returnLabels = apiResponse.data.map((item) => item.code_desc);
        return { labels: returnLabels, data: returnData };
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});
export const getByCodeType = createAsyncThunk("attendance/getByCodeType", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getByCodeType(payload);
    if (apiResponse.success) {
        const returnData = apiResponse.data.map((item) => item.count);
        const returnLabels = apiResponse.data.map((item) => item.code_type);
        return { labels: returnLabels, data: returnData };
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getByTerm = createAsyncThunk("attendance/getByTerm", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getByTerm(payload);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = ["Term 1", "Term 2", "Term 3", "Term 4", "Term 5", "Term 6"];
            const values = [0, 0, 0, 0, 0, 0];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                const index = labels.indexOf(apiResponse.data[i].data[j].term_name);
                if (index === -1) {
                    continue;
                }
                values[index] = apiResponse.data[i].data[j].count;
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const getByWeekDay = createAsyncThunk("attendance/getByWeekDay", async (payload, thunkAPI) => {
    const apiResponse = await attendanceApi.getByWeekDay(payload);
    const formattedData = [];
    if (apiResponse.success) {
        for (let i = 0; i < apiResponse.data.length; i++) {
            const labels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
            const values = [0, 0, 0, 0, 0];
            for (let j = 0; j < apiResponse.data[i].data.length; j++) {
                const index = labels.indexOf(apiResponse.data[i].data[j].dayname);
                if (index === -1) {
                    continue;
                }
                values[index] = apiResponse.data[i].data[j].count;
            }
            formattedData.push({ name: apiResponse.data[i].label, labels, values });
        }
        return formattedData;
    } else {
        return thunkAPI.rejectWithValue(apiResponse.error);
    }
});

export const attendanceSlice = createSlice({
    name: "attendance",
    initialState,
    reducers: {
        setLateReportDays: (state, action) => {
            state.lateReport.days = action.payload;
        },
        setLateReportMinutes: (state, action) => {
            state.lateReport.minutes = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getByCode.pending, (state) => {
                state.byCode.status = "loading";
            })
            .addCase(getByCode.fulfilled, (state, action) => {
                state.byCode.status = "idle";
                state.byCode.data = action.payload;
            })
            .addCase(getByCode.rejected, (state, action) => {
                state.byCode.status = "error";
                state.byCode.error = action.payload;
            })
            .addCase(getByTerm.pending, (state) => {
                state.byTerm.status = "loading";
            })
            .addCase(getByTerm.fulfilled, (state, action) => {
                state.byTerm.status = "idle";
                state.byTerm.data = action.payload;
            })
            .addCase(getByTerm.rejected, (state, action) => {
                state.byTerm.status = "error";
                state.byTerm.error = action.payload;
            })
            .addCase(getByCodeType.pending, (state) => {
                state.byCodeType.status = "loading";
            })
            .addCase(getByCodeType.fulfilled, (state, action) => {
                state.byCodeType.status = "idle";
                state.byCodeType.data = action.payload;
            })
            .addCase(getByCodeType.rejected, (state, action) => {
                state.byCodeType.status = "error";
                state.byCodeType.error = action.payload;
            })
            .addCase(getByWeekDay.pending, (state) => {
                state.byWeekDay.status = "loading";
            })
            .addCase(getByWeekDay.fulfilled, (state, action) => {
                state.byWeekDay.status = "idle";
                state.byWeekDay.data = action.payload;
            })
            .addCase(getByWeekDay.rejected, (state, action) => {
                state.byWeekDay.status = "error";
                state.byWeekDay.error = action.payload;
            })
            .addCase(getSummary.pending, (state) => {
                state.summary.status = "loading";
            })
            .addCase(getSummary.fulfilled, (state, action) => {
                state.summary.status = "idle";
                state.summary.data = action.payload;
            })
            .addCase(getSummary.rejected, (state, action) => {
                state.summary.status = "error";
                state.summary.error = action.payload;
            })
            .addCase(getAttendanceTable.pending, (state) => {
                state.attendanceTable.status = "loading";
            })
            .addCase(getAttendanceTable.fulfilled, (state, action) => {
                state.attendanceTable.status = "idle";
                state.attendanceTable.data = action.payload;
            })
            .addCase(getAttendanceTable.rejected, (state, action) => {
                state.attendanceTable.status = "error";
                state.attendanceTable.error = action.payload;
            })
            .addCase(getLateReport.pending, (state) => {
                state.lateReport.status = "loading";
            })
            .addCase(getLateReport.fulfilled, (state, action) => {
                state.lateReport.status = "idle";
                state.lateReport.data = action.payload;
            })
            .addCase(getLateReport.rejected, (state, action) => {
                state.lateReport.status = "error";
                state.lateReport.error = action.payload;
            });
    },
});

export const { setLateReportDays, setLateReportMinutes } = attendanceSlice.actions;
export default attendanceSlice.reducer;
